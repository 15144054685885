import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// Components
import Header from "../components/Header"
import About from "../components/about"
import Skills from "../components/skills"
import Footer from "../components/Footer"

const IndexPage = () => (
	<Layout>
		<SEO title="Home"/>
		<Header />
		<About />
		<Skills />
		{/*<Work></Work>*/}
		{/*<Promotion></Promotion>*/}
		<Footer />
	</Layout>
)

export default IndexPage
