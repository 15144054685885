import React from "react"
import data from "../yourdata"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Skills = () => {
	return (
		<div className="section">
			<div className="container">
				<div className="skills-container">
					<h1>Skills</h1>
					<div className="skills-grid">
						{data.skills.map((skill, index) => (
							<div className="skill" key={index}>
								<FontAwesomeIcon icon={skill.img} style={{fontSize: "5rem", color: "black"}}/>
								<p>{skill.para}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Skills
