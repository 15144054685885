// Skills Icons
import {faComments, faDesktop} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faFacebookMessenger, faGithub, faInstagram, faLinkedin, faTwitter} from "@fortawesome/free-brands-svg-icons";
// Social Icon
import logoIcon from "./images/logo.svg"
import aboutImage from "./images/consulting.jpg"

export default {
	//(Please Do Not Remove The comma(,) after every variable)
	//Change The Website Template

	//   Header Details ---------------------
	logo: {
		src: logoIcon,
		alt: "Logo"
	},
	headerTagline: [
		//Line 1 For Header
		"Vital",
		//Line 2 For Header
		"Development",
		//Line 3 For Header
		"For your business",
	],
	//   Header Paragraph
	headerParagraph:
		"We, at VITAL-Dev, strive to create web-/app-based solutions to further vitalize and develop your business. We offer consulting and full-stack development in multiple programming languages.",

	//Contact Email
	contactEmail: "info@vital-dev.com",

	// End Header Details -----------------------

	// Work Section ------------------------
	projects: [
		{
			title: "Project One", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1487837647815-bbc1f30cd0d2?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8Njl8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},
		{
			title: "Project Two", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1605153864431-a2795a1b2f95?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwyNTY3ODl8fGVufDB8fHw%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},
		{
			title: "Project Three", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1504083898675-c896ecdae86e?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjJ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},
		{
			title: "Project Four", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1492171983775-a51717616c0d?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NjB8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},
		{
			title: "Project Five", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1534239143101-1b1c627395c5?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8NzZ8fHBhc3RlbHxlbnwwfHwwfA%3D%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},
		{
			title: "Project Six", //Project Title - Add Your Project Title Here
			para:
				"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.", // Add Your Service Type Here
			//Project Image - Add Your Project Image Here
			imageSrc:
				"https://images.unsplash.com/photo-1517479149777-5f3b1511d5ad?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTAyfHxwYXN0ZWx8ZW58MHx8MHw%3D&auto=format&fit=crop&w=400&q=60",
			//Project URL - Add Your Project Url Here
			url: "http://chetanverma.com/",
		},

		/*
		If You Want To Add More Project just Copy and Paste This At The End
		,{
			title: 'Project Five',
			para: 'Something Amazing',
			imageSrc: "",
			url: ''
		}
		*/
	],

	// End Work Section -----------------------

	// About Secton --------------
	aboutParaOne:
		"VITAL-Dev has its roots in Rotterdam, one of the entrepreneurial cities in the Netherlands. We stem from the need to act as a bridge between the client's wishes and state-of-the-art technology to realize these ambitions.",
	imageParaOne:
		aboutImage,
	aboutParaTwo:
		"It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
	aboutParaThree:
		"It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",

	//   End About Section ---------------------

	// Skills Section ---------------

	//   Import Icons from the top and link it here

	skills: [
		{
			img: faComments,
			para:
				"We offer consultancy on ranging from helping you set up your software to giving advice on what technology to use to achieve your IT-goals.",
		},
		{
			img: faDesktop,
			para:
				"We offer web-/software development in several programming languages and frameworks: Python, Java, JavaScript, HTML/CSS, Angular, React, MySQL.",
		},
	],

	// End Skills Section --------------------------

	//   Promotion Section --------------------------

	promotionHeading: "Heading",
	promotionPara:
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
	// End Promotion Section -----------------

	//   Contact Section --------------

	contactSubHeading: "Let's revitalize your business",
	social: [
		// Add Or Remove The Link Accordingly
		{img: faFacebookMessenger, url: "https://m.me/viral.vital.dev"},
		{img: faFacebook, url: "https://fb.me/viral.vital.dev"},
		{img: faTwitter, url: "https://twitter.com/dev_vital"},
		{img: faLinkedin, url: "https://www.linkedin.com/company/vital-dev"},
		{img: faGithub, url: "https://github.com/VITAL-Development"},
		{img: faInstagram, url: "https://www.instagram.com/dev_vital/",},
	],

	// End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
